"use client";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { memo, useEffect, useState, useRef } from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";
import useMobile from "@/lib/hooks/useMobile";
import { Button } from "../ui/button";
import Link from "next/link";
import client from "@/lib/ApolloClient/apolloClient";
import { GqlProductBySlugInterface, GqlProductBySlugNodeInterface } from "@/lib/types/gql/product/product-by-slug.type";
import PRODUCT_BY_SLUG from "@/lib/queries/product-by-slug";
import { AddToCartButton } from "@/components/ShoppingCart/addToCart";
import { useProductAttributeStore } from "@/store/product-attribute.store";
import axios from "axios";
SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);
const spaceBetween = 0;
const slidesPerView = 1;
// https://pinshop147.pinshop.com/wp-json/ps-product-ga/v1/admin/banner/get
const banners = [
  {
    title1: "P86",
    title2: "Sound And Light Integrated <br />Electronic Whistle Flashlight",
    title3: `
      <li>Sound And Light In One</li>
      <li>|</li>
      <li>120 Decibels</li>
      <li>|</li>
      <li>1600 Lumens</li>
      <li>|</li>
      <li>Broken Window Attack Head</li>
    `,
    img: "/image/home/banner/banner1.webp",
    mImg: "/image/home/banner/banner1-m.webp",
    slug: "p86-1600lm-flashlight-with-120db-electronic-whistle",
  },
  {
    title1: "V35 X-Carry",
    title2: "NQD Platform Modular <br />Flashlight Quick Pull Set",
    title3: `
      <li>Light weight And Simple</li>
      <li>|</li>
      <li>Side Pull Lock</li>
      <li>|</li>
      <li>Bottom Clear</li>
      <li>|</li>
      <li>Can Be Rotated 360°</li>
    `,
    img: "/image/home/banner/banner2.webp",
    mImg: "/image/home/banner/banner2-m.webp",
    slug: "v35-quick-draw-holder",
  },
];
type BannerInterface = {
  btnText: string;
  image: string;
  key: string;
  link: string;
  position: string;
  text: string;
  title: string;
  weight: number;
}
export default memo(function HomeBanner() {
  const [currentIndex, setCurrentIndex] = useState(1); // 用于存储当前index的状态
  const [hasFetchedInitial, setHasFetchedInitial] = useState<boolean>(false);
  // useEffect(() => {
  //   console.log(currentIndex);
  // }, [currentIndex]);
  // 创建一个 state 用来存储 Swiper 实例
  const [swiperInstance, setSwiperInstance] = useState<any | null>(null);
  const [productData, setProductData] = useState<any | null>(null)
  const { selectAttribute } = useProductAttributeStore()
  const isMobile = useMobile();
  // 用于请求特定产品数据的函数
  const fetchProductData = async (slug: string) => {
    try {
      const res = await client.query<GqlProductBySlugInterface>({
        query: PRODUCT_BY_SLUG,
        variables: { slug },
      });
      setProductData(res.data.product);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };
  const [bannerData, setBannerData] = useState<BannerInterface[]>([])
  const getBannerData = async () => {
    try {
      const res = await axios(`${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/ps-product-qa/v1/front/banner/get`);
      console.log(res.data.data);
      // const data = await res.json();
      console.log("data---",res.data);
      if (res.data.code === 1) {
        console.log("data---",res.data.data);
        setBannerData(res.data.data)
      }
      // console.log(data);
    } catch (error) {
      console.error('Error fetching banner data:', error);
      return [];
    }
  }
  useEffect(() => {
    getBannerData()
  }, [])
  useEffect(() => {
    if (swiperInstance && !hasFetchedInitial) {
      // 手动触发一次 onTransitionEnd 逻辑
      const initialSlide = swiperInstance.realIndex;
      const match = bannerData[initialSlide]?.link.match(/\/product\/([^/]+)/);
      const slug = match ? match[1] : null;
      if (slug) {
        fetchProductData(slug);
        setHasFetchedInitial(true); // 设置标志位为 true，避免重复请求
      }
    }
  }, [swiperInstance, bannerData]);
  return (
    <Swiper
      className="group relative h-[576px] max-h-screen w-full md:h-[800px]"
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      speed={1000}
      onSwiper={setSwiperInstance} // 设置 Swiper 实例
      effect={"fade"}
      navigation={{
        // hideOnClick: true,
        nextEl: ".home-swiper-button-next",
        prevEl: ".home-swiper-button-prev",
      }}
      loop={true}
      autoplay={{
        delay: 5000,
        // disableOnInteraction: true
      }}
      // pagination={{
      //   clickable: true,
      //   bulletActiveClass: "home-swiper-pagination-bullet-active",
      //   bulletClass: "home-swiper-pagination-bullet",
      // }}
      onSlideChange={(swiper) => {
        setCurrentIndex(swiper.realIndex);
      }}
      onTransitionEnd={(swiper) => {
        // realIndex 是去掉循环后正确的索引
        let match = bannerData[swiper.realIndex].link.match(/\/product\/([^/]+)/);
        const slug = match ? match[1] : null;
        if (slug) fetchProductData(slug);
      }}
    >
      {bannerData.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="h-full text-white">
            <div className="relative h-full w-full">
              {/* <Image
                priority
                alt="banner"
                src={item.image}
                quality={100}
                fill
                style={{ objectFit: "cover" }}
              /> */}
              <img src={item.image} alt="banner" className="h-full w-full object-cover" />
              <div
                className={cn(
                  `container absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 max-md:text-center md:px-32`,
                )}
              >
                <div className={cn("mb-2 space-y-2 md:space-y-5 max-md:items-center max-md:flex max-md:flex-col")}>
                  <h2
                    className={cn(
                      "text-2xl font-bold text-main transition-all md:text-5xl xl:text-[50px]",
                      currentIndex === index
                        ? "translate-y-0 opacity-100 duration-1000"
                        : "translate-y-[-100px] opacity-0 duration-0",
                    )}
                  >
                    {item.title}
                  </h2>
                  <h4
                    className={cn(
                      "text-xl font-bold text-white transition-all md:text-4xl md:leading-[50px] xl:text-5xl xl:leading-[60px] max-md:text-center",
                      currentIndex === index
                        ? "translate-y-0 opacity-100 duration-1000"
                        : "translate-y-[-100px] opacity-0 duration-0",
                    )}

                  // dangerouslySetInnerHTML={{ __html: item.title2 }}
                  >
                    {/* {parse(item.text)} */}
                    {item.text}
                  </h4>
                  {/* <ul
                    className={cn(
                      "flex items-center text-xs font-medium transition-all md:gap-4 xl:text-[15px]",
                      currentIndex === index
                        ? "translate-y-0 opacity-100 delay-500 duration-500"
                        : "translate-y-[-50px] opacity-0 duration-0",
                    )}
                  dangerouslySetInnerHTML={{ __html: item.title3 }}
                  >
                    {parse(item.title3)}
                  </ul> */}

                  <div
                    className={cn(
                      "space-x-5 pt-[8px] transition-all max-md:hidden flex",
                      currentIndex === index
                        ? "translate-y-0 opacity-100 delay-700 duration-300"
                        : "translate-y-[-25px] opacity-0 duration-0",
                    )}
                  >
                    {/* Link 包裹在 Button 内部： 在 React 中，如果使用 Next.js 的 Link 组件，它通常会被期望直接包裹在一个元素外面，
                    而不是像这里直接嵌套在 Button 内部。尽管按钮样式可能会渲染出来，但可能会影响事件的传播，导致 Link 的点击事件无法正常触发。 */}
                    <Link href={item.link}>
                      <Button size="lg">{item.btnText}</Button>
                    </Link>
                    {/* <Link href={`/product/${item.slug}`}> */}
                    {/* <Button size="lg" className="border border-white" variant="ghost" onClick={() => handleClickBuyNow(item)}>BUY NOW</Button> */}
                    {/* </Link> */}
                    {/* <Button size="lg">
                      <Link href={`/product/${item.slug}`}>READ MORE</Link>
                    </Button> */}
                    {/* <Button size="lg" variant="ghost" className="border border-white">
                      <Link href={`/product/${item.slug}`}>BUY NOW</Link>
                    </Button> */}
                    <AddToCartButton<"SimpleProduct">
                      disabled={
                        (!!productData?.attributes?.nodes?.length
                          ? selectAttribute.length !== productData?.attributes?.nodes?.length
                          : false) ||
                        !productData ||
                        // !productData?.stockQuantity ||
                        productData?.stockStatus !== "IN_STOCK" ||
                        !productData?.databaseId ||
                        !productData?.price
                      }
                      className="bg-transparent border-[1px] w-[147px] text-sm border-white  hover:bg-white hover:text-black uppercase"
                      __typename={"SimpleProduct"}
                      buyNow={true}
                      product={{
                        databaseId: productData?.databaseId.toString() || "",
                        quantity: "1",
                        stockQuantity: productData?.stockQuantity,
                        stockStatus: productData?.stockStatus || "OUT_OF_STOCK",
                        attributes: productData?.attributes?.nodes || [],
                        item_data: (() => {
                          let item_data: Record<string, string> = {};
                          selectAttribute.map((item) => {
                            item_data[item.name] = item.value;
                          });
                          return item_data;
                        })(),
                      }}
                    />
                  </div>
                  {/* 移动端 */}
                  <div
                    className={cn(
                      "transition-all md:hidden flex-col flex items-center",
                      currentIndex === index
                        ? "translate-y-0 opacity-100 delay-700 duration-300"
                        : "translate-y-[-25px] opacity-0 duration-0",
                    )}
                  >
                    <Link href={item.link}>
                      <Button size="sm">READ MORE</Button>
                    </Link>
                    {/* <Link href={`/product/${item.slug}`}> */}
                    {/* <Button size="sm" variant="ghost" className="border border-white" onClick={(item: any) => { handleClickBuyNow(item) }}>BUY NOW</Button> */}
                    {/* </Link> */}
                    <div className="mt-2">
                      <AddToCartButton<"SimpleProduct">
                        disabled={
                          (!!productData?.attributes?.nodes?.length
                            ? selectAttribute.length !== productData?.attributes?.nodes?.length
                            : false) ||
                          !productData ||
                          // !productData?.stockQuantity ||
                          productData?.stockStatus !== "IN_STOCK" ||
                          !productData?.databaseId ||
                          !productData?.price
                        }
                        className="bg-transparent border-[1px] h-[32px] w-[95px] text-sm border-white  hover:bg-white hover:text-black"
                        __typename={"SimpleProduct"}
                        buyNow={true}
                        product={{
                          databaseId: productData?.databaseId.toString() || "",
                          quantity: "1",
                          stockQuantity: productData?.stockQuantity,
                          stockStatus: productData?.stockStatus || "OUT_OF_STOCK",
                          attributes: productData?.attributes?.nodes || [],
                          item_data: (() => {
                            let item_data: Record<string, string> = {};
                            selectAttribute.map((item) => {
                              item_data[item.name] = item.value;
                            });
                            return item_data;
                          })(),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}

      <div className="absolute left-0 top-1/2 z-[2] hidden h-24 w-full -translate-y-1/2 group-hover:block">
        <div className="home-swiper-button-prev absolute left-0 flex h-full w-12 cursor-pointer items-center justify-center rounded-lg duration-300 hover:bg-[rgba(0,0,0,0.3)]">
          <i className="ri-arrow-left-s-line text-4xl text-white"></i>
        </div>
        <div className="home-swiper-button-next absolute right-0 flex h-full w-12 cursor-pointer items-center justify-center rounded-lg duration-300 hover:bg-[rgba(0,0,0,0.3)]">
          <i className="ri-arrow-right-s-line text-4xl text-white"></i>
        </div>
      </div>
    </Swiper>
  );
});
