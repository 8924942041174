"use client";

import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { useLanguageStore } from "@/store/language.store";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { Input } from "./ui/input";
import { DialogDescription } from "@radix-ui/react-dialog";

export default function Translate({ children }: { children: ReactNode }) {
  const { setSLoadState, setLanguageList, languageList, language, setLanguage, showLanguage, setShowLanguage } =
    useLanguageStore();
  const [searchVal, setSearchVal] = useState("");
  const showLanguageList = useMemo(() => {
    if (!searchVal) {
      return languageList;
    }
    const value = searchVal.toLowerCase();
    // 根据id 或者name进行模糊匹配过滤
    return languageList.filter(
      (item) => item.name.toLowerCase().indexOf(value) !== -1 || item.id.toLowerCase().indexOf(value) !== -1,
    );
  }, [searchVal, languageList]);

  let targetLanguages = [
    "english",  // English
    "french",   // French
    "russian",  // Russian
    "spanish",  // Spanish
    "arabic",   // Arabic
    "deutsch",   // German
    "thai",     // Thai
    "japanese", // Japanese
    "korean",   // Korean
    "hindi"     // Hindi (Indian)
  ];
  const handleOnClose = () => {
    setShowLanguage(false);
  };
  useEffect(() => {
    if (languageList.length > 0) return;
    const script = document.createElement("script");
    const originalConsoleLog = console.log;
    let intervalId: NodeJS.Timer;
    if (process.env.NODE_ENV === "production") {
      console.log = () => { };
    }
    script.type = "text/javascript";
    script.id = "translate";
    script.src = "https://res.zvo.cn/translate/translate.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (!window.translate) return;
      setSLoadState(true);
      console.log("翻译加载完毕");

      window?.translate?.execute();
      // 获取本地语种
      const localLanguage = window?.translate.language.getCurrent();
      setLanguage(localLanguage);
      window.translate.request.response = function (xhr: XMLHttpRequest) {
        if (xhr.readyState !== 4) return;
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          if (data?.list) {
            setLanguageList(
              data.list.map((item: { id: string; name: string; serviceId: string }) => {
                return {
                  id: item.id,
                  code: item.serviceId,
                  name: item.name,
                  icon: item.serviceId,
                };
              }),
            );
          }
        }
      };
      window?.translate.listener.start();
      intervalId = setInterval(() => {
        window?.translate?.execute();
      }, 2000);
      script.onload = () => {
        console.log = originalConsoleLog; // Optionally restore console.log after loading
      };
    });
    return () => {
      script.remove();
      clearInterval(intervalId as unknown as number); // 转换类型为 number
      console.log = originalConsoleLog; // Restore original console.log when component unmounts
    };
  }, []);

  return (
    <Dialog open={showLanguage} onOpenChange={setShowLanguage}>
      <DialogTrigger asChild>
        <div>{children}</div>
        {/* {props.type === "icon" ? (
            <i className="ri-global-line ri-xl" onClick={() => setShowLanguage(!showLanguage)}></i>
          ) : (
            <div
              className={`cursor-pointer s-flex ignore gap-x-2 text-[13px] mr-4 ${props?.className || ""}`}
              id="translate-div"
              onClick={() => setShowLanguage(!showLanguage)}
            >
              <span className={`fi fi-${showLanguageList.find((item) => item.id === currentLanguage)?.icon}`}></span>
              {showLanguageList.find((item) => item.id === currentLanguage)?.name}
            </div>
          )} */}
      </DialogTrigger>
      <DialogContent className="md:!max-w-[60vw]">
        <DialogHeader>
          <DialogTitle className="b-flex !text-black">Choose language</DialogTitle>
        </DialogHeader>
        <DialogDescription></DialogDescription>

        <Input
          placeholder="Search language"
          onInput={(e: ChangeEvent<HTMLInputElement>) => setSearchVal(e.target.value)}
        />
        <ul
          id="language-list"
          className="ignore grid max-h-[500px] gap-x-2 gap-y-4 overflow-y-auto max-md:grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
        >
          {!!showLanguageList.length &&
            Array.from(
              new Set(
                showLanguageList.map((language) => {
                  try {
                    return JSON.stringify(language);
                  } catch (error) {
                    console.error("Error stringifying language:", error);
                    return "";
                  }
                }),
              ),
            )
              .filter((language) => language !== null)
              .map((language) => {
                try {
                  return JSON.parse(language);
                } catch (error) {
                  console.error("Error parsing language:", error);
                  return null;
                }
              })
              .filter((language) => language !== null && targetLanguages.includes(language.id))
              .map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      window?.translate.changeLanguage(item.id);
                      setLanguage(item.id);
                      handleOnClose();
                    }}
                    className={`cursor-pointer duration-300 hover:font-bold hover:text-main ${language === item.id ? "font-bold text-main" : ""}`}
                  >
                    <span className={`fi fi-${item?.icon}`}></span> {item?.name}
                  </li>
                );
              })}
        </ul>
      </DialogContent>
    </Dialog>
  );
}