"use client";

import Image from "next/image";
import Link from "next/link";
import React, { HTMLAttributes, memo, useEffect, useMemo, useState } from "react";
import Translate from "./Translate";
import { Button } from "./ui/button";
import { cn } from "@/lib/utils";
import { usePathname } from "next/navigation";
import { GqlProductCategoriesParentNode2Interface } from "@/lib/types/gql/product/product-categories-parent.type";
import { useProductStore } from "@/store/product.store";
import { useUserStore } from "@/store/user.store";
import { useCocartLogout } from "@/lib/hooks/user/useCocartLogout";

import { Dialog, DialogContent, DialogDescription, DialogTitle } from "@/components/ui/dialog";
import SignSwitch from "./SignSwitch";
import CountBadge from "@/components/Badge/count-badge";
import { useShoppingCartStore } from "@/store/shoppingCart.store";
import { CartDrawer } from "@/components/ShoppingCart/cartDrawer";
import Search from "./Search";
import MenuDrawer from "./MenuDrawer";
import Placeholder from "./Placeholder";
type NavUserList = {
  id: number;
  label: string;
  show: boolean;
  callback?: (item: NavUserList) => void;
  link?: string;
};
const transparentRouters = [
  "/",
  // "/store",
  "/explore-the-excitement/about-us",
  "/explore-the-excitement/blog",
];

interface MenuSvgProps extends HTMLAttributes<HTMLOrSVGElement> {
  open: boolean;
}
function MenuSvg(props: MenuSvgProps) {
  useEffect(() => {
    if (props.open) {
      const animTopOpen = document.getElementById("globalnav-anim-menutrigger-bread-top-open") as any;
      const animBottomOpen = document.getElementById("globalnav-anim-menutrigger-bread-bottom-open") as any;
      const animMiddleShrink = document.getElementById("globalnav-anim-menutrigger-bread-middle-shrink") as any;
      animTopOpen.beginElement();
      animBottomOpen.beginElement();
      animMiddleShrink.beginElement();
    } else {
      const animTopClose = document.getElementById("globalnav-anim-menutrigger-bread-top-close") as any;
      const animBottomClose = document.getElementById("globalnav-anim-menutrigger-bread-bottom-close") as any;
      const animMiddleExpand = document.getElementById("globalnav-anim-menutrigger-bread-middle-expand") as any;

      animTopClose.beginElement();
      animBottomClose.beginElement();
      animMiddleExpand.beginElement();
    }
  }, [props.open]);

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" {...props}>
      <polyline
        id="globalnav-menutrigger-bread-top"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="2 3, 16 3"
      >
        <animate
          id="globalnav-anim-menutrigger-bread-top-open"
          attributeName="points"
          keyTimes="0;1"
          dur="0.24s"
          begin="indefinite"
          fill="freeze"
          calcMode="linear"
          values="2 3, 16 3; 3.5 3.5, 15 15"
        ></animate>
        <animate
          id="globalnav-anim-menutrigger-bread-top-close"
          attributeName="points"
          keyTimes="0;1"
          dur="0.24s"
          begin="indefinite"
          fill="freeze"
          calcMode="linear"
          values="3.5 3.5, 15 15; 2 3, 16 3"
        ></animate>
      </polyline>

      <polyline
        id="globalnav-menutrigger-bread-middle"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="2 9, 16 9"
      >
        <animate
          id="globalnav-anim-menutrigger-bread-middle-shrink"
          attributeName="points"
          dur="0.24s"
          begin="indefinite"
          fill="freeze"
          values="2 9, 16 9; 9 9, 9 9"
        ></animate>
        <animate
          id="globalnav-anim-menutrigger-bread-middle-expand"
          attributeName="points"
          dur="0.24s"
          begin="indefinite"
          fill="freeze"
          values="9 9, 9 9; 2 9, 16 9"
        ></animate>
      </polyline>

      <polyline
        id="globalnav-menutrigger-bread-bottom"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="2 15, 16 15"
      >
        <animate
          id="globalnav-anim-menutrigger-bread-bottom-open"
          attributeName="points"
          keyTimes="0;1"
          dur="0.24s"
          begin="indefinite"
          fill="freeze"
          values="2 15, 16 15; 3.5 15, 15 3.5"
        ></animate>
        <animate
          id="globalnav-anim-menutrigger-bread-bottom-close"
          attributeName="points"
          keyTimes="0;1"
          dur="0.24s"
          begin="indefinite"
          fill="freeze"
          values="3.5 15, 15 3.5; 2 15, 16 15"
        ></animate>
      </polyline>
    </svg>
  );
}

function Header({
  allProductData,
  categories,
  hasProductCategoryMenus,
}: {
  allProductData: Map<any, any>;
  hasProductCategoryMenus: any[];
  categories: GqlProductCategoriesParentNode2Interface[] | null;
}) {
  const [isTransparent, setIsTransparent] = useState(true);
  const [cartCount, setCartCount] = useState(0);
  const { cartList, setIsOpenCart } = useShoppingCartStore();
  const productStore = useProductStore();
  const { logout } = useCocartLogout();
  const [activeId, setActiveId] = useState(1);
  const [list, setList] = useState<NavUserList[]>([]);
  const { userInfo, getUserInfo, showLoginModal, updateLoginModal } = useUserStore();
  useEffect(() => {
    productStore.updateParentCategory(categories);
  }, [categories]);

  const pathname = usePathname();

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (!transparentRouters.includes(pathname)) {
      setIsTransparent(false);
      return;
    }

    function onScroll() {
      if (openMenu) {
        setIsTransparent(false);
        return;
      }
      if (window.scrollY > 64) {
        setIsTransparent(false);
      } else {
        setIsTransparent(true);
      }
    }

    onScroll();

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [pathname, openMenu]);

  useEffect(() => {
    setCartCount(cartList?.item_count || 0);
  }, [cartList]);

  const openCartDrawer = () => {
    if (!userInfo) {
      updateLoginModal(true);
    } else {
      setIsOpenCart(true);
    }
  };

  useEffect(() => {
    setList([
      {
        id: 1,
        label: "login",
        show: !userInfo,
        callback: (option: NavUserList) => {
          updateLoginModal(true);
          setActiveId(option.id);
        },
      },
      {
        id: 2,
        label: "register",
        show: !userInfo,
        callback: (option: NavUserList) => {
          updateLoginModal(true);
          setActiveId(option.id);
        },
      },
      {
        id: 3,
        label: "Center",
        show: !!userInfo,
        link: "/center",
      },
      {
        id: 4,
        label: "Logout",
        show: !!userInfo,
        callback: logout,
      },
    ]);
  }, [userInfo]);

  const [openSearch, setOpenSearch] = useState(false);

  const [open, setOpen] = useState(false);
  const [currentHoverData, setCurrentHoverData] = useState<any>({});
  const [currentCate, setCurrentCate] = useState({ link: "/" });
  const [productData, setProductData] = useState<any>([]);
  // const [productLoading, setProductLoading] = useState(false); // 加载loading
  useEffect(()=>{
    setOpen(false)
    console.log("router---",pathname);
    console.log("allproducts-------",allProductData)
  },[pathname])
  useEffect(() => {
    getUserInfo();
    const res = hasProductCategoryMenus.find((item) => item.id === 2);
    setCurrentHoverData(res);
    const first = res?.children[0]?.children[0]?.id;
    // console.log(allProductData.get(first));
    setProductData(allProductData.get(first));
  }, []);

  return (
    <nav className={cn("sticky top-0 z-50 h-[--nav-height]", isTransparent ? "text-white" : "bg-white")} style={{borderBottom: "1px solid rgba(0,0,0,.06)"}}>
      <div className="container flex h-full items-center justify-between gap-6">
        <MenuSvg className="cursor-pointer md:hidden" open={openMenu} onClick={() => setOpenMenu(!openMenu)} />

        <Link href={"/"} className="c-flex w-[100px] md:w-[200px]">
          <Image
            src={isTransparent ? "/logo-white.png" : "/logo-black.png"}
            alt="logo"
            width={585}
            height={93}
            priority
          />
        </Link>

        <ul className="flex h-full items-center gap-6 max-md:hidden">
          {/* 顶部菜单 */}
          {hasProductCategoryMenus.map((item) => (
            <li key={item.link} className="">
              {!item.children?.length ? (
                <Link href={item.link} className="flex h-full items-center !text-sm">
                  {item.name}
                </Link>
              ) : (
                <div
                  className="group relative flex items-center !text-sm"
                  onMouseEnter={() => {
                    if (item.id === 2) {
                      setOpen(true);
                    }
                  }}
                  onMouseLeave={() => setOpen(false)}
                >
                  {item.name}
                  <i className="ri-arrow-down-s-line text-xl"></i>

                  {item.id !== 2 && (
                    <div className="absolute h-full w-full translate-y-[-10px] opacity-0 duration-150 group-hover:inline-block group-hover:translate-y-0 group-hover:opacity-100">
                      <div className="absolute left-1/2 top-full hidden -translate-x-1/2 flex-col rounded-md bg-white px-0.5 py-1 text-black shadow-lg group-hover:flex">
                        {item.children.map((item: any) => (
                          <Link
                            key={item.name}
                            href={item.link}
                            className="whitespace-nowrap rounded px-4 py-2 !text-sm hover:bg-[#ebeff2]"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </li>
          ))}

          {/* 下拉菜单内容 */}
          <div
            onMouseEnter={() => {
              setOpen(true);
            }}
            onMouseLeave={() => setOpen(false)}
            className={`w-screen ${open ? "max-h-[700px]" : "max-h-0"} absolute left-0 top-full overflow-y-auto bg-white text-black shadow duration-300 `}
          >
            <div className="container flex">
              {/* 左边菜单 */}
              <div className="whitespace-nowrap py-6 lg:py-12">
                {currentHoverData?.id === 2 && (
                  <>
                    <Link href={"/product"} className="hover:underline">
                      View all categories
                    </Link>
                    <hr className="my-4 border-t border-t-gray-300" />
                  </>
                )}
                {currentHoverData?.children?.map((category: any, i: any) => {
                  return (
                    <NavTree
                      key={i}
                      node={category}
                      getProductBySlug={(cate: any) => {
                        const sortedData = allProductData.get(cate.id).sort((a: any, b: any) => a.cate.menuOrder - b.cate.menuOrder)
                        console.log("sortedData----",sortedData);
                        setCurrentCate(cate);
                        // setProductLoading(true);
                        setProductData(sortedData);
                        // setProductLoading(false);
                      }}
                    />
                  );
                })}
              </div>
              {/* 右边产品 */}
              <div className="ml-4 flex w-full flex-1 flex-col">
                <div className="flex justify-end">
                  <button
                    onClick={() => setOpen(false)}
                    className="flex h-12 w-12 items-center justify-center rounded-full bg-white text-3xl transition-colors duration-300 hover:bg-[#f5f5f5]"
                  >
                    &times;
                  </button>
                </div>

                {currentHoverData?.id === 2 && (
                  <div className="flex h-full flex-wrap justify-between">
                    {/* {productLoading ? (
                      <NavLoading />
                    ) : ( */}
                    <div className="flex w-full flex-col">
                      {!productData?.length && <NavNoData />}

                      <>
                        {productData?.map((product: any, i: any) => {
                          const node = product.data?.products.edges.slice().sort(
                            (a: any, b: any) => a.node.menuOrder - b.node.menuOrder,
                          );
                          return (
                            <div className="mb-4" key={i}>
                              {product.cate && (
                                <div>
                                  <span className="mb-[16px] text-base font-[700] text-[#000]">
                                    {product.cate.name}
                                  </span>
                                  <Link
                                    href={`/${product.cate.slug}`}
                                    className="ml-2 cursor-pointer text-sm opacity-85 hover:underline"
                                  >
                                    READ MORE {">"}
                                  </Link>
                                </div>
                              )}
                              {!node?.length && <NavNoData />}

                              {i === 0 ? (
                                <div className="my-4 flex gap-2">
                                  <div className="my-4 grid lg:grid-cols-5 md:grid-cols-3  gap-2">
                                    {node?.slice(0, 1).map((item: any) => {
                                      return (
                                        <ProductCard
                                          className=""
                                          key={item.node.databaseId}
                                          product={item.node}
                                        />
                                      );
                                    })}
                                    {node?.slice(1).map((item: any) => {
                                      return <ProductCard key={item.node.databaseId} product={item.node} />;
                                    })}
                                    {node?.length === 6 && (
                                      <ReadMoreCard link={`${node[0]?.node?.productCategories?.edges[0]?.node?.slug ?? currentCate.link}`} />
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div className="my-4 grid grid-cols-5 gap-2">
                                  {node?.map((item: any, i2: any) => {
                                    return <ProductCard key={item.node.databaseId} product={item.node} />;
                                  })}
                                  {node?.length === 6 && <ReadMoreCard link={`/${product.cate.slug}`} />}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </>
                    </div>
                    {/* )} */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </ul>

        <ul className="flex h-full gap-x-4 font-medium">
          <li className="c-flex cursor-pointer max-md:!hidden">
            <Translate>
              <i className="ri-global-line ri-xl"></i>
            </Translate>
          </li>
          <li className="c-flex cursor-pointer max-md:hidden" onClick={() => setOpenSearch(true)}>
            <i className="ri-search-line ri-xl"></i>
          </li>
          <li className="c-flex group relative cursor-pointer max-md:hidden">
            {/* <NavUser> */}
            <i className="ri-user-line ri-xl"></i>
            {/* </NavUser> */}

            <div className="absolute h-full w-full translate-y-[-10px] opacity-0 duration-150 group-hover:inline-block group-hover:translate-y-0 group-hover:opacity-100">
              <div className="absolute left-1/2 top-full hidden -translate-x-1/2 flex-col rounded-md bg-white px-0.5 py-1 text-black shadow-lg group-hover:flex">
                {list
                  .filter((item) => item.show)
                  .map((item) =>
                    item.link ? (
                      <Link
                        href={item.link}
                        key={item.id}
                        className="whitespace-nowrap rounded px-4 py-2 !text-sm hover:bg-[#ebeff2]"
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <div
                        key={item.id}
                        onClick={() => item.callback && item.callback(item)}
                        className="whitespace-nowrap rounded px-4 py-2 !text-sm hover:bg-[#ebeff2]"
                      >
                        {item.label}
                      </div>
                    ),
                  )}
              </div>
            </div>
          </li>

          <li className="c-flex cursor-pointer" style={{ visibility: openMenu ? "hidden" : "visible" }}>
            <CountBadge count={cartCount} onClick={openCartDrawer}>
              <i className="ri-shopping-cart-line ri-xl"></i>
            </CountBadge>
          </li>

          <li className="c-flex cursor-pointer max-md:!hidden">
            <Link href={"/store"}>
              <Button size="sm" className="rounded-full">
                <i className="ri-shopping-bag-line ri-xl mr-1" />
                <p>Store</p>
              </Button>
            </Link>
          </li>
        </ul>
      </div>

      <Dialog open={showLoginModal} onOpenChange={(v) => updateLoginModal(v)}>
        <DialogTitle></DialogTitle>
        <DialogContent className="overflow-y-auto max-[550px]:w-[95%] max-[350px]:h-[95vh]">
          <DialogDescription></DialogDescription>
          <SignSwitch activeId={activeId} setActiveId={setActiveId} />
        </DialogContent>
      </Dialog>

      <CartDrawer />

      <Search open={openSearch} setOpen={setOpenSearch} />

      <MenuDrawer open={openMenu} onOpenChange={setOpenMenu} menu={hasProductCategoryMenus} />
    </nav>
  );
}

const NavTree = ({ node, getProductBySlug }: any) => {
  const hasChildren = node.children && node.children.length > 0;
  return (
    <div className="relative w-full">
      {hasChildren ? (
        <>
          <p className="text-[12px] opacity-45">{node.name}</p>
          {node.children?.map((sec_item: any, i: number) => (
            <Link
              onMouseEnter={() => getProductBySlug(sec_item)}
              href={sec_item.link}
              key={i}
              className="flex w-full hover:bg-[#f0f1f2]"
            >
              {sec_item.image && <img src={sec_item.image} className="h-[48px] w-[48px]" alt={sec_item.name} />}
              <div className="group flex cursor-pointer items-center">
                <div className="cursor-pointer px-2 py-3">{sec_item.name}</div>
              </div>
            </Link>
          ))}
        </>
      ) : (
        <NavNormalLink
          onMouseEnter={() => {
            getProductBySlug(node);
          }}
          className={"cursor-pointer font-[600]"}
          data={node}
        />
      )}
    </div>
  );
};

const NavNormalLink = ({ data, className, onMouseEnter }: { data: any; className: string; onMouseEnter: any }) => {
  return (
    data.link && (
      <Link
        onMouseEnter={onMouseEnter}
        href={data.link}
        className={`flex w-full cursor-pointer items-center hover:bg-[#f0f1f2] ${className}`}
      >
        <div className="cursor-pointer px-3 py-3">{data.name}</div>
      </Link>
    )
  );
};

const ProductCard = ({ product, className, imageWH }: any) => {
  // hover:bg-[#fafafa]
  return (
    <Link
      href={`/product/${product.slug}`}
      // target="_blank"
      // rel="noopener noreferrer"
      className={`max-w-sm cursor-pointer flex-col gap-4 overflow-hidden rounded bg-[#fff] p-5 text-center  ${className}`}
    >
      <Placeholder
        className="h-[156px]"
        width={imageWH?.width ?? 300}
        height={imageWH?.height ?? 300}
        style={{ objectFit: "contain" }}
        src={product.image?.sourceUrl}
        alt={product.name}
        priority
      />

      <div className="mb-2 justify-self-end text-sm" title={product.name}>
        {product.name}
      </div>
    </Link>
  );
};

const ReadMoreCard = ({ link }: any) => {
  // hover:bg-[#fafafa
  // bg-[#f2f2f2]
  return (
    <Link
      href={`${link}`}
      className={`c-flex max-w-sm cursor-pointer flex-col gap-4 overflow-hidden rounded bg-[#ffff] p-5 text-center ]`}
    >
      <i className="ri-skip-right-line text-4xl"></i>
      <div className="mb-2 line-clamp-2 justify-self-end text-lg">Read More</div>
    </Link>
  );
};

const NavLoading = () => {
  return (
    <div className="c-flex h-full w-full items-center">
      <svg
        className="h-5 w-5 animate-spin text-primary"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <span className="ml-2 text-sm font-medium text-primary">Loading...</span>
    </div>
  );
};

const NavNoData = () => {
  return <div className="flex h-40 w-full items-center justify-center text-2xl font-bold text-gray-300">No Data</div>;
};

export default memo(Header);
